// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = true;
// var countUpStats = true;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see ttps://fresco-docs.netlify.com/#/carousel/background-video

// Replace link for logo and 'back to main site' button

$('.mainLogo').attr('href', 'https://www.accesscharityoftheyear.org');
$("<a href='https://www.accesscharityoftheyear.org' class='backToMainSite'>Back to main site</a>").prependTo("body");

// Replace charity logo on appeal posts on main site

if ($('body').hasClass('appeal-malaysian-mental-health-association')) {
  $(".charityLogo").append("<img src='/fresco/clients/accesscoty/assets/logo-malaysia.png' />");
}

if ($('body').hasClass('appeal-romania-scoala-mamei-junior')) {
  $(".charityLogo").append("<img src='/fresco/clients/accesscoty/assets/logo-romania.png' />");
}

if ($('body').hasClass('appeal-singapore-childrens-society')) {
  $(".charityLogo").append("<img src='/fresco/clients/accesscoty/assets/logo-singapore.jpg' />");
}

if ($('body').hasClass('appeal-child-action-lanka')) {
  $(".charityLogo").append("<img src='/fresco/clients/accesscoty/assets/logo-sri-lanka.jpg' />");
}

if ($('body').hasClass('appeal-usa-mental-health-america')) {
  $(".charityLogo").append("<img src='/fresco/clients/accesscoty/assets/logo-usa.png' />");
}

if ($('body').hasClass('appeal-vietnam-saigon-childrens-charity')) {
  $(".charityLogo").append("<img src='/fresco/clients/accesscoty/assets/logo-vietnam.png' />");
}

// Find a fundraiser widget - replace search field with cta button which links to fundraiser listing page

$(".findAFundraiserWidget .searchFundraisers").replaceWith("<a href='/fundraisers' class='cta-button'>Find a fundraiser</a>");

